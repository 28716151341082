import React from 'react';
import Section from './Section';
import CareerEntry from './CareerEntry';

function Experience() {
  return (
		<Section sectionId="experience">
			<h2>Professional Experience</h2>
			<CareerEntry
				title="Senior Software Engineer"
				timeframe="March 2021 to Present"
				company="MediaNews Group Inc"
				city="Denver, Colorado"
				description="Develop and implement solutions to improve end user experience and enhance revenue focused initiatives."
				achievements={[
					"Advocate for code quality.",
					"Design and implement a serverless authorization/entitlement microservice that allows the team to be flexible in adding new subscription sources.",
					"Design and implement a session management solution to help remedy premature session expiration caused by Safari Intelligent Tracking Prevention.",
					"Utilize agile development methodologies and planning to implement well-writen deliverables on time.",
				]}
			/>
			<CareerEntry
				title="Senior Mobile Application Developer"
				timeframe="November 2019 to March 2021"
				company="MediaNews Group Inc"
				city="Denver, Colorado"
				description="Enhance the existing functionality of the mobile applications."
				achievements={[
					"Implement in-app purchasing for iOS and Android mobile apps.",
					"Develop unit tests to decrease recurring issues and improve long term maintenance.",
				]}
			/>
			<div className="page-break"><br/></div>
			<CareerEntry
				title="Mobile Application Developer"
				timeframe="April 2017 to November 2019"
				company="MediaNews Group Inc"
				city="Denver, Colorado"
				description="Enhance the existing functionality of the mobile applications."
				achievements={[
					"Architect an approach to bridge search functionality from a website to native apps.",
					"Design and develop a native app push notification system using AWS Lambda, Firebase, and NodeJS.",
					"Modernize the Mobile Dashboard codebase to bring it up to date with existing long term support versions for the Meteor, React stack.",
					"Optimize Mobile Application build pipeline to reduce deploy time for 120 apps to under 2 hours using build concurrency.",
					"Implement ESLint to maintain code quality and update the entire code base to match the teams agreed upon standards.",
					"Rapidly prototype a Web Push notification server solution as requested by leadership during contract negotians with a vendor.",
					"Develop and run A/B tests to determine traffic impacts of proposed changes to the desktop websites.",
				]}
			/>
			<CareerEntry
				title="Online Support Specialist"
				timeframe="February 2015 to April 2017"
				company="MediaNews Group Inc"
				city="Denver, Colorado"
				description="Provide front line support for issues being reported by the Newsrooms to ensure 24/7 operational success. This includes troubleshooting bugs, password resets, navigation changes, general operational and technical questions related to any of DFM's 6 Content Management System's. In addition, any available time outside of supporting users was directed towards assisting with development priorities."
				achievements={[
					"Automate the build and deployment of 120 Mobile apps using Fastlane, Ruby, Gradle, and XCode.",
					"Automate icon generation for iOS and Android mobile applications using Image Magick with Ruby.",
					"Re-brand PDF report generation software with PHP, Javascript, HTML, and CSS.",
					"Assist with identifying the most used story assets for product owner (Video embeds, Gallery embeds, etc) by writing a script to scrape and parse article content using NodeJS and MySQL.",
					"Identified and resolved a bug that prevented newsletter subscribers from being able to automatically unsubscribe from newsletters. These requests had been manually handled by my team until the bug was fixed.",
				]}
			/>
			<CareerEntry
				enabled="false"
				title="Advanced Repair Agent"
				timeframe="October 2013 to February 2015"
				company="Geek Squad"
				city="Lakewood, Colorado"
				description="Manage the in-store repair queue to ensure that all client units are repaired efficiently and quickly. Utilize soft-skills to assist Consultation Agents with client relations. Provide general technical knowledge as needed to consultation agents. Accuracy before speed."
			/>
		</Section>
  );
}

export default Experience;
