import React from 'react';
import './CareerEntry.css';

function CareerEntry( props ) {
	const {
		enabled,
		title,
		timeframe,
		company,
		city,
		description,
		achievements = [],
	} = props;
	if ( enabled === "false" ) {
		return null;
	}
	const achievementsList = achievements.map( ( achievement, index ) =>
		(<li key={`${title}-${company}-${index}`.replace(/\s/gi, '_')}> <strong>&bull;</strong> {achievement}</li>) );
	const achievementsBlock = (
		<div className="career-entry-achievements">
			<strong>Achievement Highlights</strong>
			{achievementsList}
		</div>
	);
	return (
		<div className="career-entry">
			<div className="career-entry-main">
				<strong>{title}</strong> {timeframe} - {company}, {city}
				<br/>
				{description}
			</div>
			<br/>
			{achievements.length > 0 ? achievementsBlock : ''}
		</div>
  );
}

export default CareerEntry;
