import React from 'react';
import Section from './Section';

function Tools() {
  return (
    <Section sectionId="tools">
			<h2>Programming Languages</h2>
			<ul>
				<li>Javascript</li>
				<li>PHP</li>
				<li>Ruby</li>
			</ul>
			<h2>Technical Tools</h2>
			<p><strong>AWS</strong>: Lambda, DynamoDB, API Gateway, S3, CloudWatch, IAM, EC2</p>
			<p><strong>Development</strong>: React, React Native, HTML, CSS, Redux, NodeJS, GraphQL, WordPress, Gradle, XCode</p>
			<p><strong>Testing/Quality Assurance</strong>: MochaJS, JestJS, SinonJS, ESLint, Flow</p>
			<p><strong>DevOps</strong>: Serverless, Fastlane, CircleCI, GitHub Actions, Docker, Bash</p>
		</Section>
  );
}

export default Tools;
