import React from 'react';
import './Section.css';

function Section( props ) {
  return (
    <div className="section" id={props.sectionId}>
			{props.children}
			<hr/>
		</div>
  );
}

export default Section;
