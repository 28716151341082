import React from 'react';
import Section from './Section';

function Summary() {
  return (
    <Section sectionId="summary">
			<h2>Summary</h2>
			<p>Highly skilled, experienced and motivated senior software engineer. Successfully releases applications by focusing on time management and teamwork.</p>
		</Section>
  );
}

export default Summary;
