import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import pdfCreator from 'html2pdf.js';
import './DownloadPdfButton.css';

const generatePdf = () => {
	const resume = document.getElementById( 'resume' );
	const config = {
		filename: 'resume-danieljones',
		margin: [ 0, 0, 5, 0 ],
		pagebreak: {
			mode: 'css',
			/* after: [ '.section#experience div:nth-child(3)' ], */
			before: [ '.page-break' ]
		},
	};
	pdfCreator().set( config ).from( resume ).save();
};

const DownloadPdfButton = () => {
	return (
		<div className="float pdf-download-container">
			<div className="pdf-tooltip">
				<div className="pdf-tooltiptext">
					Download PDF
				</div>
			</div>
			<button
				className="download-button center-text"
				onClick={generatePdf}
			>
			<FontAwesomeIcon
				icon={faFilePdf}
				size="2x"
				/>
			</button>
		</div>
	);
};

export default DownloadPdfButton;
