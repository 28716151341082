import React from 'react';
import './App.css';

import Resume from './components/Resume';
import DownloadPdfButton from './components/DownloadPdfButton';

function App() {
  return (
    <div className="App">
			<Resume />
			<DownloadPdfButton />
		</div>
  );
}

export default App;
