import React from 'react';

import Summary from './Summary';
import RankedSkills from './RankedSkills';
import Tools from './Tools';
import Experience from './Experience';

function Resume() {
	return (
		<main id="resume" className="resume">
			<header className="App-header">
        <h1>Daniel Jones - Senior Software Engineer</h1>
				<h4>Denver, Co 80204 | contact@danieljones.info</h4>
      </header>
			<Summary />
			<Tools />
			<Experience />
		</main>
	);
}

export default Resume;
